<template>
    <div v-if="page">
        <h2>{{$t('pool-title')}}</h2>
        <div class="row justify-content-md-center">
            <pool-box v-for="(pool, index) in page.pools" :key="pool.name" :pool="pool"  :id="index" />
        </div>
    </div>
</template>

<script>
    import PoolBox from "../components/Pool/PoolBox";
    import gql from "graphql-tag";

  export default {
    name: "Pool",
      components: {
        PoolBox
      },
      data() {
          return {
              heading: this.$i18n.t("Pool"),
              page: null
          }
      },
      apollo: {
        page: {
            query: gql`query{doc(id:"pool"){content,title},pools{name,memberCount,payoutAt,payoutAmount,lastPayoutAt,lastPayoutAmount,totalAmount,elegible,selfCount},viewer{id,totals{tokens}},viewerIsAdmin}`,
            update: data => data,
            result({data}) {
                if (data) {
                    this.$store.commit('user/updateAvailable', {
                        tokens: data.viewer.totals.tokens
                    })
                    if (data.viewerIsAdmin) {
                        this.$store.commit('user/setAdmin')
                    }
                }
            }
        }
      }
  }
</script>

<style scoped>

</style>