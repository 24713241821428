<template>
    <div class="col-md-12 col-xl-6">
        <div class="card-shadow-primary card-border mb-3 card">
            <div class="dropdown-menu-header">
                <div :class="['dropdown-menu-header-inner', pool.elegible ? 'bg-success' : 'bg-primary']">
                    <div class="menu-header-content">
                        <h5 class="menu-header-title text-capitalize mb-0 fsize-3">{{ pool.name }}</h5>
                    </div>
                </div>
            </div>
            <ul class="list-group list-group-flush">
                <li class="p-0 list-group-item">
                    <div class="row">
                        <div class="col-6 d-flex align-items-center">
                            <div class="w-100">
                                <div class="widget-chart">
                                    <div class="widget-chart-content">
                                        <div class="widget-numbers mt-0 text-dark fsize-3">
                                            <ICountUp :startVal="0" :endVal="pool.totalAmount /1e8"
                                                      :duration="1" :options="options" />
                                            <small class="fsize-1"> HTL</small>
                                        </div>
                                        <div class="widget-subheading fsize-15">{{$t('in-pool')}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 d-flex align-items-center">
                            <div class="w-100">
                                <div class="widget-chart">
                                    <div class="widget-chart-content">
                                        <div class="widget-numbers mt-0 text-dark fsize-3">
                                            <span class="count-up-wrapper-4">
                                                <ICountUp :startVal="0" :endVal="pool.memberCount" :decimals="0"
                                                        :duration="1"  />
                                            </span>
                                        </div>
                                        <div class="widget-subheading fsize-15">{{$t('pool-members')}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 d-flex align-items-center">
                            <div class="w-100">
                                <div class="widget-chart">
                                    <div class="widget-chart-content">
                                        <div class="widget-numbers mt-0 text-dark fsize-3">
                                            <ICountUp :startVal="0" :endVal="pool.lastPayoutAmount / 1e8"
                                                      :duration="1" :options="options" />
                                            <small class="fsize-1"> HTL</small>
                                        </div>
                                        <div class="widget-subheading fsize-15">{{$t('pool-last-payout')}} - <span class="d-inline-block">{{ pool.lastPayoutAt|formatDateShort }}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 d-flex align-items-center">
                            <div class="w-100">
                                <div class="widget-chart">
                                    <div class="widget-chart-content">
                                        <div class="widget-numbers mt-0 text-dark fsize-3">
                                            <ICountUp :startVal="0" :endVal="pool.payoutAmount / 1e8"
                                                      :duration="1" :options="options" />
                                            <small class="fsize-1"> HTL</small>
                                        </div>
                                        <div class="widget-subheading fsize-15">{{$t('pool-next-payout')}} - <span class="d-inline-block">{{ pool.payoutAt|formatDateShort }}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="p-0 list-group-item" v-show="!pool.elegible">
                    <div class="grid-menu grid-menu-2col overflow-hidden overflow-hidden">
                        <div class="no-gutters row">
                            <div class="col-sm-12">
                                <div class="p-1">
                                    <button type="button" class="btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-danger pool-btn" disabled>
                                        <span v-html="$t('pool-no-member', [pool.name])" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="p-0 list-group-item" v-show="pool.elegible">
                    <div class="grid-menu grid-menu-2col overflow-hidden overflow-hidden">
                        <div class="no-gutters row">
                            <div class="col-sm-12">
                                <div class="p-1">
                                    <button type="button" class="btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-success pool-btn" disabled>
                                        <span v-html="$t('pool-member', [pool.name])" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
  import ICountUp from "vue-countup-v2";
  export default {
    name: "PoolBox",
      components: {
        ICountUp
      },
      props: [
          "pool",
          "title",
          "id",
          "nopackage"
      ],
      data() {
        return {
            options: {
                useEasing: true,
                useGrouping: true,
                separator: ",",
                decimal: ".",
                prefix: "",
                decimalPlaces: 2
            }
        }
      },
  }
</script>
<style scoped>
</style>